<template>
  <div class="summary-swiper">
    <div class="summary-swiper__container">
      <Swiper
        class="summary-swiper__thumbnails"
        :slides-per-view="6"
        direction="vertical"
        @swiper="setThumbsSwiper"
      >
        <SwiperSlide
          v-for="(image, index) in product.getImages('small')"
          :key="index"
          class="summary-swiper__thumbnail-img-w"
        >
          <!-- Нужен перевод для слова Изображение -->
          <img
            :src="image"
            :alt="product.getName() + ' - Изображение ' + (index + 1)"
            class="summary-swiper__thumbnail-img"
          />
        </SwiperSlide>
      </Swiper>
      <Swiper
        class="summary-swiper__slider"
        :navigation="{
          nextEl: '.summary-swiper__slider-next-btn',
          prevEl: '.summary-swiper__slider-prev-btn',
        }"
        :thumbs="{ swiper: thumbsSwiper }"
        :pagination="isMobile"
        :grab-cursor="true"
        :zoom="true"
        :loop="true"
        :modules="[
          SwiperNavigation,
          SwiperPagination,
          SwiperThumbs,
          SwiperZoom,
        ]"
      >
        <SwiperSlide
          v-for="(image, index) in product.getImages('large')"
          :key="index"
        >
          <div class="swiper-zoom-container">
            <img
              :src="image"
              :alt="product.getName() + ' - Изображение ' + (index + 1)"
            />
          </div>
        </SwiperSlide>
        <ClientOnly>
          <div v-if="product.isOrderable" class="summary-swiper__labels">
            <LabelsNameplate
              v-for="label in product.labels"
              :key="label"
              :label="label"
            />
          </div>
          <HeaderActions :product="product" class="summary-swiper__actions" />
          <div class="summary-swiper__zoom">
            <img
              src="~~/assets/icons/zoom.svg"
              alt="zoom icon"
              class="summary-swiper__zoom-icon"
            />
            <span class="summary-swiper__zoom-caption">
              Zoom on doubleclick
            </span>
          </div>
        </ClientOnly>
        <button class="summary-swiper__slider-prev-btn">
          <img src="@/assets/icons/arrow-left.svg" alt="previous image" />
        </button>
        <button class="summary-swiper__slider-next-btn">
          <img src="@/assets/icons/arrow-right-black.svg" alt="next image" />
        </button>
      </Swiper>
    </div>
    <ul
      v-if="product.isOrderable && !getIsPoliciesFetching"
      class="summary-swiper__benefits"
    >
      <template v-for="benefit in benefits" :key="benefit.title">
        <li v-if="benefit.isShown?.() ?? true" class="summary-swiper__benefit">
          <img
            :src="benefit.image"
            :alt="benefit.title"
            class="summary-swiper__benefit-image"
            loading="lazy"
          />
          <span class="summary-swiper__benefit-title">
            {{ _T(benefit.title) }}
          </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import { useHeaderStore } from "~/store/headerStore";
import LabelsNameplate from "~/modules/shared/components/nameplate/LabelsNameplate.vue";
import { addScopedEventListener } from "~/utils/eventListener";
import HeaderActions from "~/modules/shared/components/productCard/components/HeaderActions.vue";
import { usePaymentPolicyStore } from "~/store/paymentPolicyStore";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: false },
});

onMounted(() => {
  const debounced = useDebounceFn(() => {
    getMobile();
  }, 100);

  nextTick(() => {
    getMobile();
  });

  addScopedEventListener(window, "resize", debounced);
});

const { width: windowSize } = useWindowSize();

const headerStore = useHeaderStore();
const { getHeightHeader } = storeToRefs(headerStore);

const paymentPolicyStore = usePaymentPolicyStore();
const { getPrivatBankPayment, getIsPoliciesFetching } =
  storeToRefs(paymentPolicyStore);

useAsyncData("paymentPolicyStore", () =>
  paymentPolicyStore.fetch().then(() => true),
);

const benefits = [
  /*{
    image: "/img/free-delivery.svg",
    title: "@Free shipping",
    isShown: () => isFreeDelivery(props.product),
  },*/
  {
    image: "/img/partpay_icon.svg",
    title: "@Pay Parts",
    isShown: () =>
      getPrivatBankPayment.value(
        "payParts",
        props.product.getPrice(Product.PRICE_TYPE.CURRENT),
      ).isAvailable,
  },
  /*{
    image: "/img/benefit-warranty.svg",
    title: "@Manufacturer quality assurance",
  },*/
];

const thumbsSwiper = ref(null);
const isMobile = ref(false);

const headerHeight = () => {
  return getHeightHeader.value.header;
};

const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};

const getMobile = () => {
  isMobile.value = windowSize.value <= 1024;
};
</script>

<style lang="scss"></style>

<style scoped lang="scss">
.summary-swiper {
  --space-top: 16px;
  top: calc(
    v-bind(navigationHeight) * 1px + var(--space-top) + v-bind("headerHeight()")
  );

  @include mobile {
    top: auto;
  }
}
</style>
