<template>
  <div class="summary-payment">
    <h4 class="summary-payment__title">
      {{ _T("@Ways of payment") }}
    </h4>
    <div class="summary-payment__text">
      <p>
        {{ _T("@Product payment methods") }}
      </p>
      <div v-show="isShowDetails" class="summary-payment__expanded-text">
        <p>
          {{ _T("@Product payment methods description") }}
        </p>
        <p>
          {{ _T("@Product payment methods reference") }}
          <MyLink name="delivery" class="summary-payment__link">
            {{ _T("@Delivery and payment") }}
          </MyLink>
        </p>
      </div>
    </div>
    <div
      class="summary-payment__button-details"
      :class="{ active: isShowDetails }"
      @click="isShowDetails = !isShowDetails"
    >
      <span>{{ !isShowDetails ? _T("@More details") : _T("@Hide") }}</span>
      <img src="~~/assets/icons/nav-arrow-down.svg" alt="arrow" />
    </div>
    <div class="summary-payment__icons">
      <img
        v-for="icon in paymentIcons"
        :key="icon"
        :src="`/img/${icon}.svg`"
        :alt="icon"
      />
    </div>
  </div>
</template>

<script setup>
const isShowDetails = ref(false);

const paymentIcons = [
  "google-pay-icon",
  "apple-pay-icon",
  "privat-pay-icon",
  "visa-pay-icon",
  "mastercard-pay-icon",
];
</script>

<style scoped lang="scss">
.summary-payment {
  @include flex-container(column, flex-start);
  gap: 16px;

  &__title {
    @include font(18, 20, 700);

    @include mobile {
      font-size: 16px;
    }
  }

  &__text {
    @include flex-container(column, flex-start);
    gap: 8px;

    & p {
      @include font(14, 18);
    }
  }

  &__expanded-text {
    @include flex-container(column, flex-start);
    gap: 8px;
  }

  &__link {
    color: var(--color-primary-base);
    font-weight: 500;

    text-decoration: underline;
  }

  &__button-details {
    position: relative;
    cursor: pointer;

    width: max-content;

    padding: 3px 32px 3px 0;

    transition: width 0.3s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;

      width: 0;
      border-bottom: 1px solid var(--color-primary-base);

      transition: width 0.3s ease-in-out;
    }

    & span {
      color: var(--color-primary-base);
      @include font(14, 18, 400);
    }

    & img {
      position: absolute;
      z-index: 3;
      right: 0;
      top: 0;

      transition: transform 0.3s ease-in-out;
    }

    &.active {
      & img {
        transform: rotate(180deg);
      }
    }

    &:hover {
      &::after {
        content: "";

        width: 100%;
        border-bottom: 1px solid var(--color-primary-base);
      }
    }
  }

  &__icons {
    @include flex-container(row, flex-start, center);
    flex-wrap: wrap;
    gap: 16px;

    @include mobile {
      gap: 8px;
    }
  }
}
</style>
